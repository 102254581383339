@import 'variables';
.sides-padding {
  padding-right: $sides-padding;
  padding-left: $sides-padding;
}
.text-center {
  text-align: center;
}
.m-t-10 {
  margin-top: 10em;
}

.m-t-5 {
  margin-top: 5em;
}

.m-b-1 {
  margin-bottom: 1em;
}

.m-b-5 {
  margin-bottom: 5em;
}

.m-r-1 {
  margin-right: 1em;
}

.m-l-1 {
  margin-left: 1em;
}

.text-bold {
  font-weight: 600;
}
.yellow {
  color:map-get($theme-colors, 'yellow');
}

.shadowtxt {
  text-shadow: 3px 2px 4px #00000073;
}
.letterspacing1 {
  letter-spacing: .1em;
}
.w-100 {
  width:100%;
}


@media screen  and (max-width: 1024px) /* Desktop */ {
  .sides-padding {
    padding-right: 2em;
    padding-left: 2em;
  }
}

@media screen  and (max-width: 979px) /* Tablet */ {
  .sides-padding {
    padding-right: 2em;
    padding-left: 2em;
  }
}

@media screen and (max-width: 500px) /* Mobile */ {

}


