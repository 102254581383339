button {
  &:focus {
    outline:0;
  }
}
.arrow-btn {
  border: none;
  background: transparent;
  border-bottom: 4px solid map-get($theme-colors, 'yellow');
  padding: 0 .5em .5em 0;
  user-select: none;
  cursor: pointer;
  .arrow {
    color: map-get($theme-colors, 'black');
    margin: 1em 0;

    &::after {
        display: inline-block;
        padding-left: 8px;
        content: "\0279E"; // arrow right unicode
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }

    &:hover {
        color: map-get($theme-colors, 'black');

        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
  }
}
button.normal {
  background: map-get($theme-colors, "yellow");
  border: 0;
  padding: 8px 20px;
  border-radius: 22px;
  cursor: pointer;
  transition: $btn-transitions;
  a {
    color: white;
    text-decoration: none;
  }
  &:hover {
    background:lighten(map-get($theme-colors, "yellow"), 5%)
  }
}

