@import '../abstracts/variables';
.form {
  p {
    font-weight: 600;
    font-size: 0.9em;
    margin-bottom: 0.5em;
    small {
      color: #a0a0a0;
    }
  }
  input, textarea, select {
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    border: 1px solid #ddd;
    border-radius: 0.4em;
    min-height: 2.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    color: #585858;
    outline: none;
    transition: $btn-transitions;
    &:focus {
      border: 1px solid #FFC107;
    }
  }
}

