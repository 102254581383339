@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700&display=swap');
@import url("https://use.typekit.net/ikk0pmz.css");

// module imports

@import 'abstracts/module';
@import 'base/module';
@import 'components/module';


body.bg-yellow {
    background: #ffa400;
}