@import '../abstracts/colors';


body {
  font-family: 'Montserrat', sans-serif;
}
h1 {
  font-size: 3.375em;
  color: map-get($theme-colors, "black");
}
h2 {
  font-size: 2.188em;
  color: map-get($theme-colors, "black");
}
h3 {
  font-size: 1.250em;
  color: map-get($theme-colors, "black");
}
h6 {
  font-size: 1.188em;
  font-weight: 300;
}
p {
  font-size: 1.000em;
  color: map-get($theme-colors, "gray");
  line-height: 1.8;
}
a {
  color: map-get($theme-colors, "yellow");
  &:hover {
    color:lighten(map-get($theme-colors, "yellow"), 5%)
  }
}
